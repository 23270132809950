let map = new Map();
//  后台系统首页
map.set('index', () => import('@/views/backstage/index/Index'));
//  内容管理
map.set('articlesManage', () => import('@/views/backstage/articlesManage/ArticlesManage'));
map.set('categoryManage', () => import('@/views/backstage/categoryManage/CategoryManage'));
map.set('accountManage', () => import('@/views/backstage/accountManage/AccountManage'));
map.set('messagesManage', () => import('@/views/backstage/messagesManage/MessagesManage'));

//获取动态路由
export function getRoutes(menus) {
    let actionRouter = [];
    menus.map(item => {
        // if (item.children != null && item.children.length > 0) {
        //     let children = [];
        //     item.children.map(tips => {
        //         children.push({
        //             path: tips.path,
        //             name: tips.name,
        //             component: map.get(tips.name),
        //             meta: {
        //                 title: tips.title,
        //                 icon: tips.icon,
        //             },
        //         });
        //     });

        //     actionRouter.push({
        //         path: item.path,
        //         name: item.name,
        //         component: map.get(item.name),
        //         meta: {
        //             title: item.title,
        //             icon: item.icon,
        //         },
        //         children,
        //     });
        // }
        // else {
        actionRouter.push({
            path: item.path,
            name: item.name,
            component: map.get(item.name),
            meta: {
                isAuth: true,
                // title: item.title,
                // icon: item.icon,
            },
            // children: []
        });
        // }
    });
    return actionRouter;
}

export default getRoutes;
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { Message } from "element-ui";
import getRoutes from "../plugins/getRoutes";
Vue.use(VueRouter);

// 重写VueRouter原型对象的push
// 先拷贝一份
let originPush = VueRouter.prototype.push;
// 参数1:往哪跳转,参数2:成功回调,参数3:失败回调
// call与apply传递参数,call传递参数用逗号隔开,apply方法传递数组
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    //  如果传了成功失败回调,则调用原来的push方法
    originPush.call(this, location, resolve, reject);
  } else {
    //  如果不传入,则调用重写的
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
// 重写VueRouter原型对象的replace
// 先拷贝一份
let originReplace = VueRouter.prototype.replace;
// 参数1:往哪跳转,参数2:成果回调,参数3:失败回调
// call与apply传递参数,call传递参数用逗号隔开,apply方法传递数组
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    //  如果传了成功失败回调,则调用原来的replace方法
    originReplace.call(this, location, resolve, reject);
  } else {
    //  如果不传入,则调用重写的
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

const router = new VueRouter({
  // 切换历史模式
  mode: "history",
  // 上线服务器时,文件所在路径
  base: "./",
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      name: "home",
      path: "/home",
      component: () => import("../views/home/Home"),
      meta: {
        title: "首页",
      },
    },
    {
      name: "aboutUs",
      path: "/aboutUs",
      component: () => import("../views/aboutUs/AboutUs"),
      meta: {
        title: "关于我们",
      },
    },
    {
      name: "login",
      path: "/login",
      component: () => import("../views/login/Login"),
      meta: {
        title: "登录",
      },
    },
    {
      name: "articles",
      path: "/articles",
      component: () => import("../views/articles/Articles"),
      // children: [
      //     {
      //         name: 'article',
      //         path: '/article',
      //         component: () => import('../views/articles/article/Article'),

      //     },
      // ]
    },
    {
      name: "article",
      path: "/article",
      component: () => import("../views/article/Article"),
    },
    // {
    //     name: 'myuserinfo',
    //     path: '/myuserinfo',
    //     component: () => import('../views/myuserinfo/Myuserinfo'),
    //     meta: {
    //         isAuth: true,
    //     },
    // },
    {
      name: "backstage",
      path: "/backstage",
      component: () => import("../views/backstage/Backstage"),
      meta: {
        isAuth: true,
        title: "后台管理"
      },
      children: [],
      // redirect: '/backstage/index'
    },
  ],
});

//前置路由守卫
//全局前置守卫：初始化时执行、每次路由切换前执行
let registerRouteFresh = true;
router.beforeEach((to, from, next) => {
  // console.log(from, to)
  // console.log(router.getRoutes())
  // 是否 管理员 登录,管理员登录才能进入后台
  // 是否 用户 登录,用户登录才能进入前台
  // 是否 游客
  let isUserLogin = localStorage.getItem("isUserLogin")
    ? JSON.parse(localStorage.getItem("isUserLogin"))
    : false;
  let isAdminLogin = localStorage.getItem("isAdminLogin")
    ? JSON.parse(localStorage.getItem("isAdminLogin"))
    : false;
  if (to.meta.title) {
    document.title = "玄瑞科技 | " + to.meta.title;
  }
  if (
    to.meta.isAuth ||
    (from.fullPath == "/" &&
      (to.fullPath == "/backstage/index" ||
        to.fullPath == "/backstage/articlesManage" ||
        to.fullPath == "/backstage/categoryManage" ||
        to.fullPath == "/backstage/messagesManage" ||
        to.fullPath == "/backstage/accountManage"))
  ) {
    // 是否需要权限,需要权限
    // console.log(store.state.login.resources && store.state.login.token && isAdminLogin == true)
    // console.log(store.state.login.resources && store.state.login.token && store.state.login.isAdminLogin == true)
    if (
      store.state.login.resources &&
      store.state.login.token &&
      isAdminLogin == true
    ) {
      // 如果要前往后台,判断是否管理员登录,是,添加路由,放行
      let resources = getRoutes(JSON.parse(localStorage.getItem("resources")));
      if (registerRouteFresh) {
        // arr.forEach((value, index) => {
        //     router.addRoute(value)
        // })
        for (let i = 0; i < resources.length; i++) {
          router.addRoute("backstage", resources[i]);
        }
        registerRouteFresh = false;
        // console.log(router.getRoutes())
        // next({ ...to, replace: true })
        next({ ...to, replace: true });
      } else {
        next();
      }
    } else if (to.meta.isAuth || to.fullPath == "/myuserinfo") {
      // 判断前台用户中心
      // console.log('可进入个人中心', isUserLogin, isUserLogin == true,)
      if (isUserLogin == true) {
        next();
      } else {
        router.push({ path: "/login" });
        Message({
          showClose: true,
          message: "请先前台登录!",
        });
      }
    } else {
      router.push({ path: "/login" });
      Message({
        showClose: true,
        message: "请先登录!",
      });
    }
  } else {
    // 是否需要权限,不需要权限
    next();
  }

  // if (from.fullPath === '/') {
  // if (to.meta.isAuth) { //判断当前路由是否需要进行权限控制,true: 需要权限
  //     if (store.state.login.isAdminLogin && localStorage.getItem('token')) { //权限控制的具体规则
  //         next() //放行
  //     } else {
  //         // next('/login')
  //         router.push({ path: '/login' })
  //         Message({
  //             showClose: true,
  //             message: "请先登录!"
  //         })
  //     }
  // }else{

  // }
  // next()
  /* if (to.fullPath !== '/login' && store.state.login.isAdminLogin && from.fullPath !== to.fullPath && sessionStorage.getItem('resources') && router.getRoutes().length < 9) {//这里是判断如果上个页面不是登录页，就把路由添进去
        // router.addRoutes()//这里我用到了本地存储。在页面登录成功后，遍历好的路由存到了storage里面。
        let resources = getRoutes(JSON.parse(sessionStorage.getItem('resources')))
        // console.log(resources)
        if (registerRouteFresh) {
            // arr.forEach((value, index) => {
            //     router.addRoute(value)
            // })
            for (let i = 0; i < resources.length; i++) {
                router.addRoute('backstage', resources[i])
            }
            next({ ...to, replace: true })
            registerRouteFresh = false
            // console.log(router.getRoutes())
        } else {
            next()
        }
        next()
    }
    else {
        next()
    }
    next() */
  // }
  // else {
  //     next()
  // }
  // next()
});

export default router;
